import React, { useState } from "react";
import styled from "styled-components";

import { Cols, LinkButton, Rows, Text, LinkedInIcon, TwitterIcon } from "./cdk/design-system";
import { Loader, TextLoopDisplay } from "./cdk/business";

import backgroundImage from "./images/background.jpeg";

const Background = styled.div`
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: black;
`;

const LoaderContainer = styled.div`
  display: ${({ visible }) => (visible ? "block" : "hidden")};
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  transition: opacity 1s;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -36px;
`;

const LoadedContent = styled.div`
  display: ${({ visible }) => (visible ? "unset" : "hidden")};
  & > #background-image {
    opacity: ${({ visible }) => (visible ? "1" : "0")};
    transition: opacity 1.5s ease-in;
  }
  & #content-hello {
    opacity: ${({ visible }) => (visible ? "1" : "0")};
    transition: opacity 1s;
    transition-delay: 1.5s;
  }
  & #content-description {
    opacity: ${({ visible }) => (visible ? "1" : "0")};
    transition: opacity 1s;
    transition-delay: 3s;
  }
  & #content-networks {
    opacity: ${({ visible }) => (visible ? "1" : "0")};
    transition: opacity 1s;
    transition-delay: 4s;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  filter: brightness(75%);
`;

const ContentContainerFlex = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    width: 100%;
    margin-left: 15%;
    @media only screen and (max-width: 768px) {
      margin-left: 5%;
    }
  }
`;

const skills = [
  "Florian Georget",
  "Florian Georget",
  "a full stack developer",
  "in Rennes' area in France",
  "a music lover",
];

const networks = [
  {
    icon: TwitterIcon,
    color: "#1DA1F2",
    link: "https://twitter.com/GeorgetFlorian",
  },
  {
    icon: LinkedInIcon,
    color: "#2867B2",
    link: "https://www.linkedin.com/in/florian-georget/",
  },
];

const Homepage = () => {
  const [loading, setLoading] = useState(true);
  return (
    <Background>
      <LoaderContainer visible={loading}>
        <Loader />
      </LoaderContainer>

      <LoadedContent visible={!loading}>
        <BackgroundImage
          id="background-image"
          src={backgroundImage}
          alt="Background"
          onLoad={() => setLoading(false)}
        />
        <ContentContainerFlex>
          <Rows gap="1rem">
            <Rows gap="0">
              <Text size="xbig" id="content-hello">
                Hello 👋🏻
              </Text>
              <Text size="big" id="content-description">
                <TextLoopDisplay texts={skills} prefix="I'm " />
              </Text>
            </Rows>

            <Cols id="content-networks">
              {networks.map(({ icon: NetworkIcon, color, link }, index) => (
                <LinkButton key={index} href={link} target="_blank" backgroundColor={color}>
                  <NetworkIcon />
                </LinkButton>
              ))}
            </Cols>
          </Rows>
        </ContentContainerFlex>
      </LoadedContent>
    </Background>
  );
};

export default Homepage;
