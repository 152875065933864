import { useState } from "react";

const TextLoopDisplay = ({ prefix, texts }) => {
  const [index, setIndex] = useState(0);

  setTimeout(() => setIndex(index < texts.length - 1 ? index + 1 : 0), 4000);

  return `${prefix}${texts[index]}`;
};

export default TextLoopDisplay;
