import styled from "styled-components";
import { darken } from "polished";

const LinkButton = styled.a`
  padding: 0.5rem;
  background-color: ${({ backgroundColor = "#fff" }) => backgroundColor};
  border-radius: 0.5rem;
  text-decoration: none;
  font-size: 0;
  & > svg {
    width: 32px;
    height: 32px;
    fill: white;
  }
  &:hover {
    background-color: ${({ backgroundColor }) => darken(0.1, backgroundColor)};
  }
`;

export default LinkButton;
