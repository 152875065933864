import styled, { css } from "styled-components";

const sizeEnum = {
  small: "0.75em",
  normal: "1em",
  medium: "2em",
  big: "3em",
  xbig: "4.5em",
};

const Text = styled.div`
  color: white;
  font-size: ${({ size }) => sizeEnum[size] || size};
  overflow-wrap: break-word;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`;

export default Text;
